import React from 'react';

import { Link, graphql } from 'gatsby';
import { Layout, SEO } from 'components';

import { PDFViewer, PDFSlideViewer } from 'components/PDFViewer';
import useYoshiAudio from '../../utils/yoshi';
import PlaySoundButton from 'components/PlaySoundButton';

import { download_file } from '../../utils/utils';
import VideoPlayer from 'components/VideoPlayer';

let yoshiSpriteIdx = 0;
let yoshiJumpingSpriteIndex = 0;

const VOLUME = 0.5;

/**
 * @param {{
 *    data: {
 *      interactiveLearningPage: {
 *        description: string
 *        title: string
 *        horizontalPdf: boolean
 *        pdfLink?: string
 *        subscriptionOnly: boolean
 *        downloadablePdf: boolean
 *        video?: {
 *          video: {
 *              streamingUrl: string
 *           }
 *        },
 *        youtubeVideo?: string
 *        paragraphs?: {
 *          paragraph: string
 *          audioClip?: {
 *            url?: string
 *          }
 *          buttonLabel?: string
 *        }[]
 *        seo?: {
 *          title: string
 *          description: string
 *          image?: {
 *              url: string
 *          }
 *        }
 *        seoKeywords?: {
 *          keyword: string
 *        }[],
 *        schemaMarkup: string
 *      }
 *    }
 * }} param
 */
export default function CoordinatePage({ data }) {
  const {
    description,
    title,
    pdfLink,
    horizontalPdf,
    paragraphs,
    seo,
    seoKeywords,
    schemaMarkup,
    subscriptionOnly,
    video,
    videos,
    youtubeVideo,
    downloadablePdf,
  } = data.interactiveLearningPage;
  /**
   *  @type {React.MutableRefObject<HTMLCanvasElement> | null} canvasRef
   */
  const canvasRef = React.useRef(null);

  /**
   * @type {React.MutableRefObject<HTMLDivElement> | null} canvasContainerRef
   */
  const canvasContainerRef = React.useRef(null);
  const [context, setContext] = React.useState(null);
  const [canvas, setCanvas] = React.useState(null);
  const [messages, setMessages] = React.useState([]);

  const [start, setStart] = React.useState(false);
  const [x, setX] = React.useState('0');
  const [y, setY] = React.useState('0');

  const [currX, setCurrX] = React.useState(0);
  const [currY, setCurrY] = React.useState(0);
  const [isMuted, setIsMuted] = React.useState(false);
  const [playSongDuringAnimation, setPlaySongDuringAnimation] = React.useState(false);

  const [yoshi, setYoshi] = React.useState();
  const [yoshiRunningLeft, setYoshiRunningLeft] = React.useState();
  const [yoshiJumping, setYoshiJumping] = React.useState();
  const [yoshiFalling, setYoshiFalling] = React.useState();

  const yoshiAudio = useYoshiAudio();

  React.useEffect(() => {
    setYoshi(() => {
      const image = new Image();
      image.src = `${window.location.origin}/yoshi-running-sprites-101x101.png`;
      return image;
    });
    setYoshiRunningLeft(() => {
      const image = new Image();
      image.src = `${window.location.origin}/yoshi-running-left.png`;
      return image;
    });
    setYoshiJumping(() => {
      const image = new Image();
      image.src = `${window.location.origin}/yoshi-jumping.png`;
      return image;
    });
    setYoshiFalling(() => {
      const image = new Image();
      image.src = `${window.location.origin}/yoshi-falling.png`;
      return image;
    });
    //i.e. value other than null or undefined
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      setCanvas(canvas);
      setContext(ctx);
    }
  }, []);

  React.useEffect(() => {
    if (canvas && context) {
      initialiseGrid();
    }
  }, [context, canvas]);

  let curr_x = currX;
  let curr_y = currY;

  let xDuration;
  let xStartTime;
  let yDuration;
  let yStartTime;
  let rhymeStartTime;
  let rhymeDuration;
  let cuteDuration;
  let cuteStartTime;
  let returningToOrigin = false;
  let x0Played = false;
  let y0Played = false;
  let zeroDuration;
  let zeroStartTime;
  let waitDuration = 5 * 1000;
  let waitStartTime;

  function animate() {
    const step = 1 / 16;

    if (Number.isInteger(curr_x) && Number.isInteger(curr_y)) {
      setCurrX(curr_x);
      setCurrY(curr_y);
    }

    if (parseInt(x) === 0 && parseInt(y) === 0 && !returningToOrigin) {
      const sound = yoshiAudio.origin;
      if (!isMuted) sound.play();
      setMessages((prev) => [...prev, sound.text]);
      returningToOrigin = true;
      zeroDuration = sound.duration;
      zeroStartTime = Date.now();
      return requestAnimationFrame(animate);
    }
    if (zeroDuration && zeroDuration && Date.now() - zeroStartTime < zeroDuration) {
      return requestAnimationFrame(animate);
    }

    if (parseInt(x) === 0 && !returningToOrigin && !x0Played) {
      if (!xDuration) {
        const sound = yoshiAudio.zero.x;
        setMessages((prev) => [...prev, sound.text]);
        if (!isMuted) sound.play();
        xDuration = sound.duration;
        xStartTime = Date.now();
      }
      x0Played = true;
      return requestAnimationFrame(animate);
    } else if (curr_x < parseInt(x) && !returningToOrigin) {
      if (!xDuration) {
        const sound = yoshiAudio.right[parseInt(x) - 1];
        if (!isMuted) sound.play();
        setMessages((prev) => [...prev, sound.text]);
        xDuration = sound.duration;
        xStartTime = Date.now();
      }
      curr_x += step;
      drawGridV2(canvas, context, start, curr_x, curr_y);
      return requestAnimationFrame(animate);
    } else if (curr_x > parseInt(x) && !returningToOrigin) {
      if (!xDuration) {
        const sound = yoshiAudio.left[Math.abs(parseInt(x)) - 1];
        setMessages((prev) => [...prev, sound.text]);
        if (!isMuted) sound.play();
        xDuration = sound.duration;
        xStartTime = Date.now();
      }
      curr_x -= step;
      drawGridV2(canvas, context, start, curr_x, curr_y, true);
      return requestAnimationFrame(animate);
    } else if (parseInt(y) === 0 && !returningToOrigin && !y0Played) {
      if (!yDuration) {
        const sound = yoshiAudio.zero.y;
        setMessages((prev) => [...prev, sound.text]);
        if (!isMuted) sound.play();
        yDuration = sound.duration;
        yStartTime = Date.now();
      }
      y0Played = true;
      return requestAnimationFrame(animate);
    } else if (curr_y < parseInt(y) && !returningToOrigin) {
      if (xDuration && Date.now() - xStartTime < xDuration) {
        return requestAnimationFrame(animate);
      }
      if (!yDuration) {
        const sound = yoshiAudio.up[parseInt(y) - 1];
        setMessages((prev) => [...prev, sound.text]);
        if (!isMuted) sound.play();
        yDuration = sound.duration;
        yStartTime = Date.now();
      }
      curr_y += step;
      drawGridV2(canvas, context, start, curr_x, curr_y, false, true);
      return requestAnimationFrame(animate);
    } else if (curr_y > parseInt(y) && !returningToOrigin) {
      if (xDuration && Date.now() - xStartTime < xDuration) {
        return requestAnimationFrame(animate);
      }
      if (!yDuration) {
        const sound = yoshiAudio.down[Math.abs(parseInt(y)) - 1];
        setMessages((prev) => [...prev, sound.text]);
        if (!isMuted) sound.play();
        yDuration = sound.duration;
        yStartTime = Date.now();
      }
      curr_y -= step;
      drawGridV2(canvas, context, start, curr_x, curr_y, true, true);
      return requestAnimationFrame(animate);
    } else {
      if (yDuration && Date.now() - yStartTime < yDuration) {
        return requestAnimationFrame(animate);
      }
      if (!rhymeStartTime && parseInt(y) !== 0) {
        const sound = yoshiAudio.rhyme[Math.abs(parseInt(y)) - 1];
        setMessages((prev) => [...prev, sound.text]);
        if (!isMuted) sound.play();
        rhymeDuration = sound.duration;
        rhymeStartTime = Date.now();
        return requestAnimationFrame(animate);
      }
      if (rhymeStartTime && Date.now() - rhymeStartTime < rhymeDuration) {
        return requestAnimationFrame(animate);
      }
      if (!cuteStartTime) {
        const randomIndex = Math.floor(Math.random() * yoshiAudio.cute.length);
        const sound = yoshiAudio.cute[randomIndex];
        if (!isMuted) sound.play();
        cuteDuration = sound.duration;
        cuteStartTime = Date.now();
        returningToOrigin = true;
        return requestAnimationFrame(animate);
      }
      if (cuteStartTime && Date.now() - cuteStartTime < cuteDuration) {
        return requestAnimationFrame(animate);
      }
      if (!waitStartTime) {
        setCurrX(parseInt(x));
        setCurrY(parseInt(y));
        waitStartTime = Date.now();
        return requestAnimationFrame(animate);
      }
      if (waitDuration && Date.now() - waitStartTime < waitDuration) {
        return requestAnimationFrame(animate);
      }
      if (curr_x < 0) {
        curr_x += step;
        drawGridV2(canvas, context, start, curr_x, curr_y);
        return requestAnimationFrame(animate);
      } else if (curr_x > 0) {
        curr_x -= step;
        drawGridV2(canvas, context, start, curr_x, curr_y, true);
        return requestAnimationFrame(animate);
      } else if (curr_y < 0) {
        curr_y += step;
        drawGridV2(canvas, context, start, curr_x, curr_y, false, true);
        return requestAnimationFrame(animate);
      } else if (curr_y > 0) {
        curr_y -= step;
        drawGridV2(canvas, context, start, curr_x, curr_y, true, true);
        return requestAnimationFrame(animate);
      }
      drawGridV2(canvas, context, false, 0, 0);
      setCurrX(0);
      setCurrY(0);
      setX('0');
      setY('0');
      setMessages([]);
      if (!playSongDuringAnimation) {
        if (!isMuted) yoshiAudio.mainSong.play();
      }
    }
  }

  function drawGridV2(
    canvas,
    ctx,
    start = false,
    yoshi_x = 0,
    yoshi_y = 0,
    oppositeDirection = false,
    isMovingUp = false,
  ) {
    const grid_size = 40;
    const x_axis_distance_grid_lines = 11;
    const y_axis_distance_grid_lines = 11;
    const x_axis_starting_point = { number: 1, suffix: '' };
    const y_axis_starting_point = { number: 1, suffix: '' };

    // canvas width
    const canvas_width = canvas.width;

    // canvas height
    const canvas_height = canvas.height;

    ctx.resetTransform();
    ctx.clearRect(0, 0, canvas_width, canvas_height);

    // no of vertical grid lines
    const num_lines_x = Math.floor(canvas_height / grid_size);

    // no of horizontal grid lines
    const num_lines_y = Math.floor(canvas_width / grid_size);

    // Draw grid lines along X-axis
    for (let i = 0; i <= num_lines_x; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;

      // If line represents X-axis draw in different color
      if (i === x_axis_distance_grid_lines) ctx.strokeStyle = '#000000';
      else ctx.strokeStyle = '#e9e9e9';

      if (i == num_lines_x) {
        ctx.moveTo(0, grid_size * i);
        ctx.lineTo(canvas_width, grid_size * i);
      } else {
        ctx.moveTo(0, grid_size * i + 0.5);
        ctx.lineTo(canvas_width, grid_size * i + 0.5);
      }
      ctx.stroke();
    }
    // Draw grid lines along Y-axis
    for (let i = 0; i <= num_lines_y; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;

      // If line represents Y-axis draw in different color
      if (i == y_axis_distance_grid_lines) ctx.strokeStyle = '#000000';
      else ctx.strokeStyle = '#e9e9e9';

      if (i == num_lines_y) {
        ctx.moveTo(grid_size * i, 0);
        ctx.lineTo(grid_size * i, canvas_height);
      } else {
        ctx.moveTo(grid_size * i + 0.5, 0);
        ctx.lineTo(grid_size * i + 0.5, canvas_height);
      }
      ctx.stroke();
    }
    ctx.translate(
      y_axis_distance_grid_lines * grid_size + 1,
      x_axis_distance_grid_lines * grid_size,
    );

    // Ticks marks along the positive X-axis
    for (let i = 1; i < num_lines_y - y_axis_distance_grid_lines; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';

      // Draw a tick mark 6px long (-3 to 3)
      ctx.moveTo(grid_size * i + 0.5, -3);
      ctx.lineTo(grid_size * i + 0.5, 3);
      ctx.stroke();

      // Text value at that point
      ctx.font = '14px Arial';
      ctx.textAlign = 'start';
      ctx.fillText(
        x_axis_starting_point.number * i + x_axis_starting_point.suffix,
        grid_size * i - 2,
        15,
      );
    }

    // Ticks marks along the negative X-axis
    for (let i = 1; i < y_axis_distance_grid_lines; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';

      // Draw a tick mark 6px long (-3 to 3)
      ctx.moveTo(-grid_size * i + 0.5, -3);
      ctx.lineTo(-grid_size * i + 0.5, 3);
      ctx.stroke();

      // Text value at that point
      ctx.font = '14px Arial';
      ctx.textAlign = 'end';
      ctx.fillText(
        -x_axis_starting_point.number * i + x_axis_starting_point.suffix,
        -grid_size * i + 3,
        15,
      );
    }
    // Ticks marks along the positive Y-axis
    // Positive Y-axis of graph is negative Y-axis of the canvas
    for (let i = 1; i < num_lines_x - x_axis_distance_grid_lines; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';

      // Draw a tick mark 6px long (-3 to 3)
      ctx.moveTo(-3, grid_size * i + 0.5);
      ctx.lineTo(3, grid_size * i + 0.5);
      ctx.stroke();

      // Text value at that point
      ctx.font = '14px Arial';
      ctx.textAlign = 'start';
      ctx.fillText(
        -y_axis_starting_point.number * i + y_axis_starting_point.suffix,
        8,
        grid_size * i + 3,
      );
    }

    // Ticks marks along the negative Y-axis
    // Negative Y-axis of graph is positive Y-axis of the canvas
    for (let i = 1; i < x_axis_distance_grid_lines; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000';

      // Draw a tick mark 6px long (-3 to 3)
      ctx.moveTo(-3, -grid_size * i + 0.5);
      ctx.lineTo(3, -grid_size * i + 0.5);
      ctx.stroke();

      // Text value at that point
      ctx.font = '14px Arial';
      ctx.textAlign = 'start';
      ctx.fillText(
        y_axis_starting_point.number * i + y_axis_starting_point.suffix,
        8,
        -grid_size * i + 3,
      );
    }

    if (!start) drawYoshiEgg(ctx, grid_size);
    else {
      drawYoshi(ctx, grid_size, yoshi_x, yoshi_y, oppositeDirection, isMovingUp);
    }
  }

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {number} grid_size
   */

  function drawYoshiEgg(ctx, grid_size) {
    const yoshiEgg = new Image();
    yoshiEgg.src = `${window.location.origin}/yoshi-egg-sprite.webp`;
    yoshiEgg.onload = () => {
      const width = yoshiEgg.width * ((1 / grid_size) * 2.5);
      const height = yoshiEgg.height * ((1 / grid_size) * 2.5);
      ctx.drawImage(yoshiEgg, -0.5 * width, -0.5 * height, width, height);
    };
  }

  const yoshiSpriteMap = [
    [0, 0],
    [1, 0],
    [0, 1],
    [1, 1],
  ];
  const yoshiJumpingSpriteMap = [
    [0, 0],
    [1, 0],
  ];

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {number} grid_size
   */
  function drawYoshi(ctx, grid_size, x, y, oppositeDirection = false, isMovingUp = false) {
    if (!isMovingUp) {
      const spriteSize = 202;
      yoshiSpriteIdx = (yoshiSpriteIdx + 1) % 16;
      const [spriteX, spriteY] = yoshiSpriteMap[Math.floor(yoshiSpriteIdx / 4)];
      const scale = 1.25;
      ctx.drawImage(
        oppositeDirection ? yoshiRunningLeft : yoshi,
        spriteSize * spriteX,
        spriteSize * spriteY,
        spriteSize,
        spriteSize,
        x * grid_size - grid_size * 0.5,
        -1 * y * grid_size - grid_size,
        grid_size * scale,
        grid_size * scale,
      );
    } else {
      const spriteSizeX = oppositeDirection ? 30 : 32;
      const spriteSizeY = oppositeDirection ? 49 : 51;
      yoshiJumpingSpriteIndex = oppositeDirection ? 0 : (yoshiJumpingSpriteIndex + 1) % 32;
      const [spriteX, spriteY] = yoshiJumpingSpriteMap[Math.floor(yoshiJumpingSpriteIndex / 16)];
      const scale = 1.25;

      ctx.drawImage(
        oppositeDirection ? yoshiFalling : yoshiJumping,
        spriteSizeX * spriteX,
        spriteSizeY * spriteY,
        spriteSizeX,
        spriteSizeY,
        x * grid_size - grid_size * 0.5,
        -1 * y * grid_size - grid_size,
        grid_size * scale,
        (grid_size + 4) * scale,
      );
    }
  }

  function scrollIntoView() {
    canvasRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  const handleStart = () => {
    setStart(true);
    scrollIntoView();
    drawGridV2(canvas, context, true, 0, 0);
    yoshiAudio.mainSong.play();
  };

  const handleAnimation = () => {
    if (x === '-' || y === '-') return;
    if (Math.abs(parseInt(x)) > 10) return;
    if (Math.abs(parseInt(y)) > 10) return;
    scrollIntoView();
    if (!playSongDuringAnimation) {
      yoshiAudio.mainSong.pause();
    }
    drawGridV2(canvas, context, true, curr_x, curr_y, false, true);
    requestAnimationFrame(animate);
  };

  const initialiseGrid = () => {
    yoshiSpriteIdx = 0;
    drawGridV2(canvas, context, start, 0, 0);
    setCurrX(0);
    setCurrY(0);
    setX('0');
    setY('0');
  };

  const handleInput = (value, setter) => {
    const isValueValid = /^-?\d*$/.test(value);

    if (!isValueValid) return;
    setter(value);
  };

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  return (
    <Layout>
      <SEO
        description={seo && seo.description}
        image={seo && seo.image}
        title={(seo && seo.title) ?? `Interactive Learning-${title}`}
        seoKeywords={seoKeywords}
        schema={schemaMarkup && JSON.parse(schemaMarkup)}
      />

      <div className="container co-ord-container">
        <Link className="brainteaser-breadcrumb" to="/interactive-learning">
          Interactive Learning
        </Link>
        <h1>Co-ordinates</h1>
        <p className="mobile-warning">For the best experience, please view this page on desktop.</p>
        <p className="1.25">Move Yoshi around to learn how co-ordinates work.</p>
        <div className="srow x-between co-ord-row">
          <div className="scolumn 3">
            {!start && (
              <button className="button my-1" onClick={handleStart}>
                Start!
              </button>
            )}

            {start && (
              <>
                <p>
                  Yoshi's current coordinate is{' '}
                  <span className="bold">
                    ({currX}, {currY})
                  </span>
                </p>
                <p>Enter coordinates to move Yoshi around the grid.</p>
                <div className="srow">
                  <span className="1.25">( </span>
                  <input
                    style={{ width: '3rem' }}
                    type="text"
                    id="x"
                    value={x}
                    onChange={(e) => handleInput(e.target.value, setX)}
                  />
                  <span className="1.25">, </span>
                  <input
                    style={{ width: '3rem' }}
                    type="text"
                    id="y"
                    value={y}
                    onChange={(e) => handleInput(e.target.value, setY)}
                  />
                  <span className="1.25"> )</span>
                </div>

                <div>
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={playSongDuringAnimation}
                      onChange={() => setPlaySongDuringAnimation((state) => !state)}
                    />
                    Play song during animation
                  </label>
                </div>
                <div className="my-1">
                  <button className="button" onClick={handleAnimation}>
                    Go!
                  </button>
                  <button className="button" onClick={initialiseGrid}>
                    Reset
                  </button>
                  <button
                    className="button"
                    onClick={() => {
                      setIsMuted((prev) => !prev);
                      yoshiAudio.mainSong.pause();
                    }}
                  >
                    {isMuted ? 'Unmute' : 'Mute'}
                  </button>
                  {!isMuted && (
                    <>
                      {yoshiAudio.mainSong.isPlaying && !isMuted ? (
                        <button className="button" onClick={() => yoshiAudio.mainSong.pause()}>
                          Pause
                        </button>
                      ) : (
                        <button className="button" onClick={() => yoshiAudio.mainSong.play()}>
                          Play
                        </button>
                      )}
                    </>
                  )}
                </div>
                {messages.length ? (
                  <div className="co-ordinate-message-container">
                    {messages.map((message, i) => (
                      <p key={i}>{message}</p>
                    ))}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className="scolumn narrow" ref={canvasContainerRef}>
            <div>
              <canvas ref={canvasRef} id="canvas" width="900" height="900" />
            </div>
          </div>
        </div>
        {paragraphs.length > 0 ? (
          paragraphs.map(({ paragraph, audioClip, buttonLabel }) => (
            <>
              <div dangerouslySetInnerHTML={{ __html: paragraph }} />
              {audioClip?.url && (
                <PlaySoundButton className="my-1" label={buttonLabel} soundUrl={audioClip.url} />
              )}
            </>
          ))
        ) : (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {allVideos.length ? allVideos.map((src) => <VideoPlayer src={src} />) : null}
        <div className="pb-3">
          {pdfLink && (
            <>
              {downloadablePdf && (
                <button
                  className="button my-1"
                  onClick={() => download_file(pdfLink, 'co-ordinates')}
                >
                  Download PDF
                </button>
              )}
              {horizontalPdf ? (
                <PDFSlideViewer url={pdfLink} hideWaterMark={true} />
              ) : (
                <PDFViewer url={pdfLink} hideWaterMark={true} toolbar={false} />
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

const query = graphql`
  query CoordinateQuery($id: String!) {
    interactiveLearningPage: datoCmsInteractiveLearningPage(originalId: { eq: $id }) {
      title
      pdfLink
      horizontalPdf
      description
      subscriptionOnly
      youtubeVideo
      downloadablePdf
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      paragraphs {
        audioClip {
          url
        }
        paragraph
        buttonLabel
      }
      seo {
        description
        image {
          url
        }
        title
      }
      seoKeywords {
        keyword
      }
      schemaMarkup
    }
  }
`;

export { query };
