import React from 'react';
import useSound from 'use-sound';

/**
 * @param {{
 *   label: string,
 *   soundUrl: string,
 *   className?: string
 * }} param
 * @returns {React.ReactElement}
 */
export default function PlaySoundButton({ label = 'Play audio', soundUrl, className }) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [play, { pause }] = useSound(soundUrl, {
    onend: () => setIsPlaying(false),
    onplay: () => setIsPlaying(true),
    onpause: () => setIsPlaying(false),
  });
  return (
    <button className={`button ${className}`} onClick={() => (isPlaying ? pause() : play())}>
      {isPlaying ? 'Pause' : 'Play'} {label}
    </button>
  );
}
